import axios from 'axios'

let config = {
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
}

const AxiosApp = axios.create(config)

export default AxiosApp
