<template>
  <v-app>
    <v-main>
      <router-view/>      
    </v-main>
  </v-app>
</template>

<style>
  .container {
    background-color: #01579B;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .card {
    padding: 64px 40px 40px 40px;
    width: 366px;
  }

  .errorMessage {
    margin-top: 20px;
    color: red;
    text-align: center;
  }

  .logo {
    margin-bottom: 40px;
  }
</style>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
