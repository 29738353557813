<template>
  <v-container class="container" fluid fill-height>
    <ProductNotFound />
  </v-container>
</template>

<script>

import ProductNotFound from '@/components/ProductNotFound'

export default {
  name: 'Home',

  components: {ProductNotFound},

  data: () => ({
    //
  }),
};
</script>
