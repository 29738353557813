<template>
  <v-layout align-center class="justify-center">
    <v-flex xs12>
      <v-layout align-center column>
        <v-card class="card justify-center">
          <v-img 
            src="@/assets/logo_nrmemories.png"
            contain 
            height="120px"
            class="logo"
          />

          <p class="text-h5 text-center">
            {{ $t('product-not-found') }}
          </p>
        </v-card>
      </v-layout>
     </v-flex>
  </v-layout>
</template>

<script>

export default {
  name: 'ProductNotFound',

  data: () => ({
    //
  }),
};
</script>
